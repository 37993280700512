
import register from '~/mixins/pages/register/register'

export default {
    name: 'Theme3LoginModal',
    mixins: [register],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isConditional: false,
        }
    },
    watch: {
        acceptCondition(value) {
            if (value) {
                this.isConditional = false
            } else {
                this.isConditional = true
            }
        },
    },
    methods: {
        onCloseModal() {
            this.modal = false
            this.$store.commit('themes/settings/setDisplayRegisterModal', false)
            this.$emit('close')
        },
        register() {
            if (this.acceptCondition) {
                if (this.$refs.form.validate()) {
                    this.onUserRegister()
                }
            } else {
                this.isConditional = true
                this.$toast.error(this.tl('pleaseCheckCheckCondition'))
            }
        },
    },
}
